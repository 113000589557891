import React from 'react';
import * as styles from './workList.module.scss';
import cx from 'classnames';

function WorkList({ children, ...props}) {

    const classes = cx(
        styles.workList
    );

    return (
        <div className={classes}>
            <div className={styles.workList_inner}>
                <div className={styles.workList_list}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default WorkList;