import React from "react";
import {Helmet} from "react-helmet";
import {graphql} from 'gatsby';
//import { useTranslation } from "react-i18next";
import {Heading} from "../components/01_atoms/Heading/Heading";

import * as pages from '../components/03_organisms/Pages/pages.module.scss';
import * as worklist from '../components/02_molecules/WorkList/workList.module.scss';
import Section from "../components/03_organisms/Section/Section";
import WorkItem from "../components/02_molecules/WorkItem/WorkItem";
import {Breadcrumb} from "../components/02_molecules/Breadcrumb/Breadcrumb";
import {BreadcrumbItem} from "../components/02_molecules/Breadcrumb/BreadcrumbItem";
import {PageTransition} from "../components/03_organisms/PageTransition/PageTransition";

import FadeInUp from "../components/01_atoms/FadeInUp/FadeInUp";
import Wrapper from '../components/03_organisms/Wrapper/Wrapper';
import WorkList from "../components/02_molecules/WorkList/WorkList";
import WorkCard from "../components/02_molecules/WorkCard/WorkCard";
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";

const Work = ({data, pageContext, ...props}) => {

    // read language
    const sizes = ['xl', 'l', 'm'];

    // render html
    return (
        <Wrapper>
            <HeadInject />
            <PageTransition>
                <Helmet bodyAttributes={{class: pages.work}}/>


                <Section content={false}>
                    <Breadcrumb>
                        <BreadcrumbItem to="/work" title="Work" active/>
                    </Breadcrumb>
                </Section>


                <Section>
                    <FadeInUp>
                        <Heading element="h1" size="xxxl" align="center">{data.datoCmsWorkOverview.title}</Heading>
                    </FadeInUp>
                    <FadeInUp delay=".25">
                        <Heading element="h2" size="m" align="center" font="main" weight="400">{data.datoCmsWorkOverview.subtitle}</Heading>
                    </FadeInUp>
                </Section>


                <Section content={false}>
                    <FadeInUp delay=".5">
                        <WorkList>
                          {data.allDatoCmsWork.edges.map((work, index) => {
                            return(
                              <div className={worklist.workList_item} key={index}>
                                <WorkCard work={work.node} image={work.node.hero[0].heroImage} />
                              </div>
                            )
                          })}
                        </WorkList>
                    </FadeInUp>
                </Section>

            </PageTransition>
        </Wrapper>
    )
}

export default Work;


export const query = graphql`
query WorkOverviewQuery {
  datoCmsWorkOverview {
		id
		title
		subtitle
		slug
  }
  allDatoCmsWork(sort: {order: ASC, fields: position}) {
    edges {
      node {
        id
        slug
        title
        intro {
          value
        }
        client {
					slug
          name
        }
        hero {
          heroImage {
            gatsbyImageData
            format
            url
            alt
          }
        }
      }
    }
  }
}
  
`