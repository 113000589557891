import React from 'react';

function Wrapper({children, ...props}) {

    return (
        <>
            {children}
        </>
    );

}

export default Wrapper;
