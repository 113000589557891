import React from 'react';


export const PageTransition = ({children, ...props}) => {

    return (
        <>

        {/* <motion.div

            animate={{
                opacity: 1
            }}

            initial={{
                opacity: 0
            }}

            exit={{
                opacity: 0
            }}
            transition={{
                duration: .2,
                ease: "linear"
            }}
        > */}

        {children}

        {/* </motion.div> */}

{/*
        <motion.div className={styles.pane}
            initial={{
                y: 0
            }}
            animate={{
                y: '100%'
            }}
            exit={{
                y: 0
            }}
            transition={{
                duration: 1,
                ease: [0.43,0.13,0.23,0.96]
            }}
        >


        </motion.div>

        <motion.svg
            className={styles.logo}
            width="60" height="100" viewBox="0 0 60 100" fill="none" xmlns="http://www.w3.org/2000/svg"
            exit={{
                opacity: 1,
                scale: 1,
                transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                    delay: .2
                  }
            }}
            animate={{
                scale: 0,
                opacity: 0,
                transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                  }
            }}

            initial={{
                scale: 0,
                opacity: 0,
                transition: {
                    ease: 'easeInOut',
                    duration: 0.5,
                  }
            }}

        >
        <path d="M59.5435 75.2037C59.42 87.547 52.2237 95.7865 39.9592 98.7325C36.0875 99.6637 31.7747 100 27.663 100H2.45995C0.825866 100 0 99.0476 0 97.5321V52.8718C0 51.3598 0.825866 50.4038 2.45995 50.4038H28.3936C31.817 50.4038 36.6381 50.7543 39.5604 51.4306C51.8531 54.2739 59.42 62.8569 59.5435 75.2037Z" fill="white"/>
        <path d="M2.53407 45.0888C0.850571 45.0888 0 44.1222 0 42.5925V2.49628C0 0.963103 0.850571 0 2.53407 0H24.6595C27.8465 0 32.3429 0.322215 35.064 0.941858C46.5167 3.54436 53.5683 11.2456 53.6848 22.5479C53.5683 33.8502 46.732 41.3745 35.304 44.0726C32.8229 44.6604 28.2347 45.0923 24.0772 45.0923H2.53407" fill="white"/>
        </motion.svg> */}

        </>
    )

    // return (

    //     children

    // )
}