import React, { useRef } from 'react';
import * as styles from './workItem.module.scss';

import { Heading } from '../../01_atoms/Heading/Heading';
import { navigate } from 'gatsby';

import { useTranslation } from "react-i18next";
import { GatsbyImage } from "gatsby-plugin-image"
import Button from '../../01_atoms/Button/Button';

import { motion } from 'framer-motion';


import cx from 'classnames';



function Work({
    children,
    title,
    client,
    link,
    image,
    odd,
    alt,
    size,
    spacing = 'default',
    ...props}) {

    const {t} = useTranslation();
    
    const parentRef = useRef(null);
    const workItemRef = useRef(null);
    const visualRef = useRef(null);

    // define classes
    const classname = cx(
        styles.work,
        styles[`spacing_${spacing}`],
        styles[`size_${size}`],
        { [`${styles.odd}`]: odd}

    );

    const navigateToDetail = to => {

        navigate(to,{
            state: { 'animationData': parentRef.current.getBoundingClientRect()}
        })
    }



    return (
        <div className={classname}>
           
            <div ref={parentRef} className={styles.image}>
                <motion.div ref={visualRef} >
                    { image && 
                        (image.format === "mp4" ? 
                            <video playsInline muted  loop autoPlay>
                                <source src={image.url}></source>
                                <track kind="caption"></track>
                            </video> : 
                            <GatsbyImage image={image.gatsbyImageData} alt={alt ? alt : ""}></GatsbyImage> 
                        )
                    }
                </motion.div>
            </div>
            
            <div className={styles.info} ref={workItemRef}>
                <div className={styles.title}><Heading element="h2" size="xl" font="special10" uppercase><span>{title} </span></Heading></div>
                <div className={styles.title}><Heading element="h3" size="m" uppercase font="special" ><span>{client} </span></Heading></div>
                <div className={styles.button}><Button compact custom onClick={()=>{ navigateToDetail(link) }} white>{t('workblock_cta')}</Button></div>
            </div>
        </div>
    );
}

export default Work;