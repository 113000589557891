import React, { useRef } from 'react';
import * as styles from './workCard.module.scss';
import cx from 'classnames';
import { navigate } from 'gatsby';
import { Heading } from '../../01_atoms/Heading/Heading';
import { GatsbyImage } from "gatsby-plugin-image"
import { RandomHoverEffect } from '../../01_atoms/RandomHoverEffect/RandomHoverEffect';

function WorkCard({ work, image, children, ...props}) {

    const classes = cx(
        styles.workCard
    );

    const parentRef = useRef(null);

    const navigateToDetail = to => {

        navigate(to,{
            state: { 'animationData': parentRef.current.getBoundingClientRect()}
        })
    }

    return (
        <RandomHoverEffect isCard={true}>
            <div ref={parentRef} className={classes}>
                <button className={styles.workCard__link} onClick={()=>{ navigateToDetail(work.client.slug +'/'+ work.slug) }}>&nbsp;</button>
                <div className={styles.workCard__visual}>
                    { image && 
                        (image.format === "mp4"? 
                            <video playsInline muted  loop autoPlay><source src={image.url}></source></video> : 
                            <GatsbyImage class={styles.workCard__image} image={image.gatsbyImageData} alt={work.alt ? work.alt : ""}></GatsbyImage> 
                        )
                    }
                </div>
                <div className={styles.workCard__content}>
                    <Heading element="h3" size="xs" spacing="s" font="main" weight="700"><span>{work.client.name}</span></Heading>
                    <Heading element="h2" size="m"><span>{work.title}</span></Heading>
                </div>
            </div>
        </RandomHoverEffect>
    );
}

export default WorkCard;