// extracted by mini-css-extract-plugin
export var button = "workItem-module--button--8ca1a";
export var image = "workItem-module--image--4583d";
export var info = "workItem-module--info--f5f6e";
export var odd = "workItem-module--odd--8d1f9";
export var size_l = "workItem-module--size_l--d85bb";
export var size_m = "workItem-module--size_m--94375";
export var spacing_0 = "workItem-module--spacing_0--86e67";
export var spacing_l = "workItem-module--spacing_l--daa78";
export var spacing_m = "workItem-module--spacing_m--741bc";
export var spacing_s = "workItem-module--spacing_s--d6c65";
export var spacing_xl = "workItem-module--spacing_xl--ac5a7";
export var spacing_xxl = "workItem-module--spacing_xxl--6a8bc";
export var spacing_xxxl = "workItem-module--spacing_xxxl--2ffa9";
export var title = "workItem-module--title--7ebbe";
export var work = "workItem-module--work--bb6d2";